import React, { useState, useReducer } from "react";
import { Grid, Typography, Button, Paper, Alert } from "@mui/material";
import { MenuItem } from "../../../components/menuInventory/MenuItem";
import { MenuEditingDialog } from "../../../components/menuInventory/MenuEditingDialog";
import { MenuConfirmation } from "../../../components/menuInventory/MenuConfirmation";
import { PageLayout } from "../../Layout/PageLayout";
import { useLoaderData, useNavigate, useParams } from "react-router";
import { DeleteForeverRounded } from "@mui/icons-material";
import { deleteMenu } from "../../../services/menuServices";

const menuSelectionReducer = (state, action) => {
  if (action.type === "ADD_NEW_MENU") {
    return {
      menu_name: null,
      isEditing: true,
      isWaitingForConfirmed: false,
    };
  } else if (action.type === "MODIFY_MENU") {
    return {
      menu_name: action.value,
      isEditing: true,
      isWaitingForConfirmed: false,
    };
  } else if (action.type === "CANCEL_EDITING") {
    return {
      menu_name: null,
      isEditing: false,
      isWaitingForConfirmed: false,
    };
  } else if (action.type === "WAITING_FOR_CONFIRMATION") {
    return { ...state, isWaitingForConfirmed: true };
  } else if (action.type === "EDITING_CONFIRMED") {
    console.log("editing confirmed");
  }

  throw Error("Unknown action");
};

export const InventoryMenu = () => {
  const [editingMenuState, dispatchEditMenu] = useReducer(
    menuSelectionReducer,
    { menu_name: null, isEditing: null, isWaitingForConfirmed: false }
  );

  const payload = useLoaderData();
  const navigate = useNavigate();
  const URLParams = useParams();
  const idRestaurant = useParams.id;

  const data = payload?.data;

  const onEditClick = (name) => {
    dispatchEditMenu({
      type: "MODIFY_MENU",
      value: name,
    });
  };

  const onMenuDelete = async (name) => {
    //delete menu by name
    let menuToDelete = data.find((value) => value.name === name);
    console.log(menuToDelete);
    let idMenu = menuToDelete.id_menu;

    let deleted = await deleteMenu(idMenu);

    navigate("");
  };

  const onCancelEditing = (event, reason) => {
    console.log(reason);
    dispatchEditMenu({ type: "CANCEL_EDITING" });
  };

  const onMenuAddedConfirmation = () => {
    dispatchEditMenu({ type: "WAITING_FOR_CONFIRMATION" });
  };

  let menuEditedItem = data.find(
    (value) => value.name === editingMenuState.menu_name
  );
  const addItemBaseInfo = { id_restaurant: data[0]?.id_restaurant };

  //Menus actions
  const actionMenu = [
    // { title: "Edit", action: onEditClick, icon: <EditIcon /> },
    { title: "Delete", action: onMenuDelete, icon: <DeleteForeverRounded /> },
    { title: "Archived", action: () => console.log("Archived") },
  ];

  return (
    <>
      <PageLayout
        pageTitle="Menus"
        primaryAction={[
          {
            action: () => dispatchEditMenu({ type: "ADD_NEW_MENU" }),
            title: "Create menu",
          },
        ]}
      >
        <Grid item container direction="column" gap={"2em"} width={"100%"}>
          <Alert variant="filled" severity="info">
            Here you will provide the list of menus that will be displayed on
            your online store
          </Alert>
          <Paper
            sx={{
              p: 2,
            }}
          >
            {data &&
              data.map((value) => {
                return (
                  <MenuItem
                    marginBottom={"8px"}
                    {...value}
                    key={value.name}
                    onClick={onEditClick}
                    actionMenu={actionMenu}
                  />
                );
              })}
          </Paper>
        </Grid>
      </PageLayout>

      {
        <MenuEditingDialog
          menuItemInfo={menuEditedItem}
          open={
            editingMenuState.isEditing && editingMenuState.menu_name !== null
          }
          onCloseHanlder={onCancelEditing}
          onMenuAddHandler={onMenuAddedConfirmation}
        />
      }
      {
        <MenuEditingDialog
          menuItemInfo={addItemBaseInfo}
          open={
            editingMenuState.isEditing && editingMenuState.menu_name === null
          }
          onCloseHanlder={onCancelEditing}
          onMenuAddHandler={onMenuAddedConfirmation}
        />
      }
      {
        <MenuConfirmation
          open={editingMenuState.isWaitingForConfirmed}
          onCloseHanlder={onCancelEditing}
        />
      }
    </>
  );
};
